@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  height: 56px;
  width: 360px;
}

.Toastify__toast--default {
  display: flex;
  align-items: center;
}

.Toastify__toast-body {
  padding: 4px 5px 4px 2px;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  align-self: center;
}
/** Classes for the progress bar **/
.Toastify__progress-bar {
  opacity: 0.1 !important;
}